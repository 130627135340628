import { data } from "autoprefixer";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Notification from "../../../components/notifications";

export default {
  name: "SuratBuktiRahn",
  components: {},
  data() {
    return {
    };
  },
};
